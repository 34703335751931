import React from 'react';

const SmallBusinessTeamSVG = ({ className }) => {
    return (
        <svg
            className={className}
            width={785}
            height={385}
            viewBox="0 0 785 385"
            fill="none">
            <path
                d="M565.444 235H196.302L63 385h646L565.444 235z"
                fill="#EEF3F7"
            />
            <path
                d="M209.742 256.36l349.49 3.415M209.742 279.61l349.49 3.416M164.216 312.288l429.966 3.415M147.699 341.939l458.209 3.415M226.82 244.976l-79.121 117.825M269.791 248.96l-32.158 95.042M324.17 250.569l-15.37 93.433M382.415 251.667v124.656M430.594 255.221l16.507 118.395M486.945 252.945l40.982 118.963M546.142 246.114c.911 2.277 46.676 85.57 69.444 126.932"
                stroke="#fff"
            />
            <path
                d="M153.432 298.789c-8.87-2.957-18.483-26.538-22.181-37.958l27.012-39.665c6.066 14.311 18.88 42.991 21.609 43.219 3.412.284 27.652-4.692 37.177-3.839 9.525.853 29.286 9.668 29.855 10.876.455.967.474 2.678.427 3.412 5.473 2.488 5.828 3.554 3.696 6.113-1.706 2.047-6.445 2.37-8.601 2.275 1.682.545 4.819 2.005 3.909 3.483-1.137 1.848-3.257 2.843-6.326 2.843-3.811 0-16.349-2.132-25.306-1.848-7.165.228-21.657 2.749-28.007 3.981 3.839.569 22.889 9.241 20.046 11.515-2.843 2.275-42.224-.71-53.31-4.407z"
                fill="#2B4959"
            />
            <path
                d="M238.974 282.319c-6.166-2.23-20.036-6.575-26.183-6.119-6.148.455-5.929.853-11.669 2.134M243.385 273.781c-5.882-2.42-19.012-7.372-24.476-7.827-6.829-.569-12.663 1.565-14.94 1.708M185.04 291.141c-.711-.284-9.246-4.696-14.513-5.123"
                stroke="#000"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M117.68 62c7.674 62.945 34.021 144.605 54.32 179.302L129.656 266C105.178 224.159 77.263 135.693 69 67.917L117.68 62z"
                fill="#213845"
            />
            <path
                d="M71 74.957L120.158 69c3.644 29.793 11.456 63.75 20.842 95-37.169-14.534-62.154-65.418-70-89.043z"
                fill="#213845"
                style={{
                    mixBlendMode: 'multiply'
                }}
            />
            <path
                d="M562.616 242.38c2.282-5.583 13.645-27.937 19.041-38.417l39.863 20.946-10.74 20.441c-.113 21.625-14.494 34.121-19.938 37.946-6.074 4.268-11.802 2.603-9.498.39 2.304-2.213 14.838-15.518 13.697-22.965-1.141-7.447-13.266-14.47-21.826-9.349-8.56 5.122-22.359 36.348-26.105 42.858-3.746 6.509-5.777 4.79-6.665 3.997-.888-.793 19.318-48.869 22.171-55.847z"
                fill="#EDCA6C"
            />
            <path
                d="M572.612 269.662c-5.998-3.543-5.632-11.224-5.467-15.409l-6.271 10.932c.395 2.882 5.873 9.756 13.628 11.557 7.754 1.801 12.777-2.556 12.149-3.509-.628-.953-9.075-.638-14.039-3.571z"
                fill="#EDCA6C"
            />
            <path
                d="M576.853 264.854c-3.715-2.734-8.104-8.183-4.318-14.227l-8.31 8.163c.103 2.907 4.86 10.297 12.394 12.87 7.534 2.573 12.969-1.256 12.441-2.267-.529-1.011-7.563-1.121-12.207-4.539z"
                fill="#EDCA6C"
            />
            <path
                d="M570.882 253.268c-1.741 1.1-5.916 4.913-8.698 11.367m3.546-6.436c.083 2.057-.155 7.445 7.576 10.604M607.971 237.438c.786.881 2.413 3.712 2.632 7.992"
                stroke="#EDCA6C"
                strokeWidth={2}
                style={{
                    mixBlendMode: 'multiply'
                }}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M664.744 167.12c-13.647 25.434-31.056 49.99-44.953 66.88L582 203.005c12.218-14.849 27.735-36.768 39.634-58.943 5.94-11.071 10.716-21.721 13.782-31.233 3.154-9.783 3.996-16.83 3.579-21.271L687.688 87c1.286 13.696-1.562 27.902-5.717 40.79-4.242 13.159-10.395 26.597-17.227 39.33z"
                fill="#699FE3"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M664.744 167.12c-13.647 25.434-31.056 49.99-44.953 66.88L582 203.005c12.218-14.849 27.735-36.768 39.634-58.943 5.94-11.071 10.716-21.721 13.782-31.233 3.154-9.783 3.996-16.83 3.579-21.271L687.688 87c1.286 13.696-1.562 27.902-5.717 40.79-4.242 13.159-10.395 26.597-17.227 39.33z"
                fill="#699FE3"
                style={{
                    mixBlendMode: 'multiply'
                }}
            />
            <path
                d="M639.024 91.542c.416 4.425-.425 11.448-3.577 21.197a123.762 123.762 0 01-1.447 4.215c8.28 11.846 23.808 42.24 19.679 69.046a375.872 375.872 0 0011.079-19.158c6.828-12.689 12.977-26.08 17.217-39.193 4.152-12.844 6.999-27.001 5.714-40.649l-48.665 4.542z"
                fill="#2B63CA"
                style={{
                    mixBlendMode: 'multiply'
                }}
            />
            <path
                d="M746.078 209.074l-50.77 4.665 38.523 52.119 42.706-3.924 4.268-5.878-34.727-46.982z"
                fill="#BFD4E0"
            />
            <path
                fill="#ADC8D7"
                d="M703.588 217.254l39.071-3.59 1.86 2.515-39.072 3.59zM708.47 223.858l39.071-3.59 1.86 2.515-39.072 3.59zM713.894 231.196l39.071-3.59 1.86 2.515-39.072 3.59zM718.775 237.8l23.106-2.123 1.859 2.515-23.106 2.123zM773.496 255.944l2.91 6.001 4.252-5.876-7.162-.125z"
            />
            <path
                d="M164 344c32.452-17.445 102.594-51.766 123.547-49.491 26.19 2.844 111.022 25.599 133.227 20.479C438.537 310.892 516.993 269.289 554 249"
                stroke="#6A9A5C"
                strokeWidth={10}
            />
            <path
                d="M422.469 177.846c8.673-.153 31.918-8.108 39.432-10.852l20.546 47.929c-1.811 1.649-38.012 18.181-45.837 22.995-6.26 3.851-18.671 9.499-24.094 11.841l-21.47-39.745-8.249-2.317c-6-1.686-18.78-4.197-28.855-.015-12.518 5.196-18.816 3.615-16.903.646 1.912-2.969 13.252-11.651 31.192-19.827 17.94-8.176 43.396-10.465 54.238-10.655z"
                fill="#EDCA6C"
            />
            <path
                d="M345.747 191.64c-5.387-1.204-29.352-13.76-37.036-20.306l-36.358 40.912c15.086 18.215 24.772 21.419 38.352 37.762 13.579 16.344 54.933 32.816 66.564 35.796 11.63 2.981 23.805.396 24.928-3.148 1.122-3.544-.913-5.643.179-7.351 1.093-1.708 5.699-.729 7.467-3.307 1.768-2.579-.779-5.913.539-7.911 1.318-1.999 6.364.065 8.522-4.049 2.252-4.294-1.678-8.07-.618-10.035 1.061-1.966 4.797-1.391 6.406-3.165 1.608-1.773 1.507-4.64.021-8.116s-26.744-25.928-38.51-34.126c-11.766-8.198-33.723-11.451-40.456-12.956z"
                fill="#2B4959"
            />
            <path
                d="M341.005 208.114c1.889-2.984 13.159-11.757 31.033-20.076 5.743-2.673 12.266-4.744 18.835-6.334l4.152 27.664-8.268-2.251c-6.013-1.638-18.813-4.047-28.854.216-12.476 5.296-18.787 3.765-16.898.781zM341.011 276.413c1.546 5.098 12.797 3.985 18.005 2.857-1.684-.814-5.926-2.698-7.626-4.252-2.126-1.943-.438-23.692-3.445-22.344-3.007 1.348-8.866 17.367-6.934 23.739z"
                fill="#EDCA6C"
            />
            <path
                d="M331.906 269.623c1.55 4.991 12.351 4.04 17.558 2.94-1.685-.797-5.48-2.774-7.182-4.296-2.127-1.903-.459-23.189-3.465-21.872-3.005 1.316-8.849 16.989-6.911 23.228z"
                fill="#EDCA6C"
            />
            <path
                d="M323.34 263.37c1.493 4.989 11.948 4.023 16.988 2.916-1.629-.796-5.299-2.766-6.944-4.286-2.056-1.9-.412-23.187-3.323-21.867-2.91 1.321-8.588 17.001-6.721 23.237z"
                fill="#EDCA6C"
            />
            <path
                d="M314.207 257.606c1.546 5.098 12.349 4.115 17.557 2.987-1.684-.813-5.477-2.829-7.178-4.383-2.126-1.942-.438-23.692-3.444-22.344-3.007 1.348-8.867 17.367-6.935 23.74z"
                fill="#EDCA6C"
            />
            <path
                d="M407.605 235.104c-.61 2.572-2.727 8.397-6.312 11.114-4.481 3.397-5.024 7.336-7.348 10.499-2.325 3.163-9.214 11.187-9.912 13.688"
                stroke="#2B4959"
                strokeWidth={2}
                style={{
                    mixBlendMode: 'multiply'
                }}
            />
            <path
                d="M344.607 191.448c-12.643-.295-34.671-7.592-43.771-11.731l7.662-8.169c15.086 10.815 25.671 14.388 36.109 19.9z"
                fill="#2B4959"
                style={{
                    mixBlendMode: 'color-burn'
                }}
            />
            <path
                d="M391.075 207.929c3.007.769 10.513 1.786 16.479-.297M341.519 267.113c-.262 1.105-.785 3.749-.785 5.485M332.063 260.702c-.262 1.105-.785 3.75-.785 5.486M323.458 255.217c-.262 1.105-.786 3.749-.785 5.485"
                stroke="#EDCA6C"
                strokeWidth={2}
                style={{
                    mixBlendMode: 'multiply'
                }}
            />
            <path
                d="M31.632 352.792l44.39-88.682-31.67 3.774-42.575 84.908h29.855z"
                fill="#BFD4E0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.777 352.792l1.039 3.207H32.41l43.612-88.115v-3.774l-44.39 88.682H1.777z"
                fill="#ADC8D7"
            />
            <path
                d="M10.848 344.098H26.5M16.966 330.864h15.652M24.791 316.776h15.653M31.073 303.115h15.653M41.451 281.867l15.645-.479M37.21 293.109l15.646-.479M44.893 272.727l15.621-.982"
                stroke="#ADC8D7"
            />
            <path
                fill="#ADC8D7"
                d="M1.776 242.745l76.869-15.66 2.112 10.368-76.869 15.66z"
            />
            <path
                d="M80.76 237.452l-2.11-10.35 14.355 2.321-12.246 8.029z"
                fill="#BFD4E0"
            />
            <path
                d="M89.98 228.95l3.06.466-2.62 1.696-.44-2.162z"
                fill="#2B4959"
            />
            <ellipse
                cx={2.869}
                cy={247.921}
                rx={1.85}
                ry={5.291}
                transform="rotate(-11.515 2.869 247.921)"
                fill="#BFD4E0"
            />
            <ellipse
                rx={0.578}
                ry={1.654}
                transform="scale(-1 1) rotate(11.515 -1230.49 109.955)"
                fill="#ADC8D7"
            />
            <path
                d="M36.275 246.515c2.919-.595 30.05-10.32 43.25-15.108l1.232 6.046-44.482 9.062z"
                fill="#ADC8D7"
                style={{
                    mixBlendMode: 'multiply'
                }}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M729 13.268c-3.66 16.478-19.369 60.39-74.564 113.163-26.161 25.013-60.864 45.315-92.878 60.597-32.331 15.434-63.918 26.686-85.4 32.972L459 161.296c18.629-5.451 47.122-15.571 76.237-29.47 29.433-14.051 57.545-31.044 76.969-49.615C659.181 37.296 668.447 3.968 669.329 0L729 13.268z"
                fill="#699FE3"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M40 12.458c3.584 16.552 24.55 69.535 78.604 122.547 25.62 25.127 59.606 45.521 90.958 60.873 31.663 15.505 62.596 26.807 83.635 33.122L310 170.029c-18.244-5.476-46.147-15.642-74.661-29.604-28.825-14.114-56.355-31.185-75.378-49.84C113.957 45.465 104.883 11.985 104.02 8L40 12.458z"
                fill="#3C647C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M778.145 335.192c-25.558 11.925-62.692 5.68-82.943-13.949-20.25-19.629-15.949-45.208 9.609-57.133l73.334 71.082zm-28.118-9.688c-12.042 5.619-31.109 1.155-42.587-9.971-11.478-11.126-11.021-24.7 1.021-30.319l41.566 40.29z"
                fill="#BFD4E0"
            />
            <path
                d="M695.307 272.516l11.406 7.045M686.032 288.871l11.121 2.186M690.066 309.682l9.199-1.682M709.664 329.246l5.485-5.85M738.053 338.58l1.335-9.019M762.578 337.576l-7.841-10.752"
                stroke="#4D809E"
            />
        </svg>
    );
};

export default SmallBusinessTeamSVG;
