import React from 'react';
import ReactMarkdown from 'react-markdown';

import styles from './BuiltForSmallBusiness.module.scss';

const BuiltForSmallBusiness = (props) => {
    const { title, description } = props;
    return (
        <section>
            <div className={styles.BuiltForSmallBusiness}>
                <h2>{title}</h2>
                <ReactMarkdown source={description} />
            </div>
        </section>
    );
};

export default BuiltForSmallBusiness;
