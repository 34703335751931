import React from 'react';

import classnames from 'classnames';

import styles from './SellerProcessExplanation.module.scss';
import GetPaidUpFrontImage from './GetPaidUpFrontImage';
import GiveBetterTermsImage from './GiveBetterTermsImage';
import GetMoreBusinessImage from './GetMoreBusinessImage';

const SellerProcessExplanation = ({
    title1,
    description1,
    title2,
    description2,
    title3,
    description3
}) => {
    return (
        <section className={styles.SellerProcessExplanation}>
            <div className={styles.SellerProcessExplanationPart}>
                <GetPaidUpFrontImage
                    className={classnames(
                        styles.Image,
                        styles.GetPaidUpFrontImage
                    )}
                />
                <SellerProcessExplanationText
                    title={title1}
                    description={description1}
                />
            </div>
            <div className={styles.SellerProcessExplanationPart}>
                <GiveBetterTermsImage
                    className={classnames(
                        styles.Image,
                        styles.GiveBetterTermsImage
                    )}
                />
                <SellerProcessExplanationText
                    title={title2}
                    description={description2}
                />
            </div>
            <div className={styles.SellerProcessExplanationPart}>
                <GetMoreBusinessImage
                    className={classnames(
                        styles.Image,
                        styles.GetMoreBusinessImage
                    )}
                />
                <SellerProcessExplanationText
                    title={title3}
                    description={description3}
                />
            </div>
        </section>
    );
};

export default SellerProcessExplanation;

const SellerProcessExplanationText = ({ title, description }) => {
    return (
        <div>
            <div className={styles.SellerProcessExplanationTitle}>{title}</div>
            <div className={styles.SellerProcessExplanationDescription}>
                {description}
            </div>
        </div>
    );
};
