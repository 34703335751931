import React from 'react';
import styles from './FooterLinks.module.scss';
import IwLink from '../../../../../components/IwLink/IwLink';

const FooterLinks = () => {
    const footerData = [
        { title: 'How it works', url: '#howitworks' },
        {
            title: 'FAQs',
            url:
                'https://support.iwoca.co.uk/en/collections/2145896-what-s-iwocapay'
        },
        { title: 'Privacy policy', url: '/privacy-policy/' },
        { title: 'Terms of use', url: '/terms-of-use/' },
        { title: 'Use of cookies', url: '/use-of-cookies/' },
        { title: 'Complaint policy', url: '/complaints/' }
    ];

    return (
        <section className={styles.FooterLinksBackground}>
            <div className={styles.FooterLinks}>
                <div className={styles.FooterLinksContainer}>
                    {footerData.map((link) => (
                        <IwLink className={styles.FooterLink} to={link.url}>
                            {link.title}
                        </IwLink>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default FooterLinks;
