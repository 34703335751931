import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import ReactMarkdown from 'react-markdown';

import MainCtas from '../MainCTAs/MainCTAs';
import styles from './AboveTheFold.module.scss';
import SellerProcessExplanation from './SellerProcessExplanation/SellerProcessExplanation';
import DynamicTrustpilot from 'aphrodite-shared/ui/DynamicTrustpilot/DynamicTrustpilot';
import ArrowSVG from 'aphrodite-shared/SVGComponents/ArrowSVG';

const AboveTheFold = ({
    headlineContent,
    button,
    buttonHref,
    sellerProcessExplanationTitle1,
    sellerProcessExplanationDescription1,
    sellerProcessExplanationTitle2,
    sellerProcessExplanationDescription2,
    sellerProcessExplanationTitle3,
    sellerProcessExplanationDescription3
}) => {
    function onArrowClick() {
        const innerHeight =
            typeof window !== 'undefined' ? window.innerHeight : '600';
        scroll.scrollMore(innerHeight - 220); // try exclude title of section
    }

    return (
        <section className={styles.AboveTheFold}>
            <div>
                <h1 className={styles.Headline}>
                    <ReactMarkdown source={headlineContent} />
                </h1>
            </div>
            <MainCtas
                primaryButton={button}
                primaryButtonHref={buttonHref}
                primaryButonGaId="top__iwocapaycta"
                secondaryButonGaId="top__iwocapaybookingcta"
            />
            <div className={styles.BelowCTA}>
                <div>
                    <DynamicTrustpilot className={styles.DynamicTrustpilot} />
                    <ArrowSVG className={styles.arrow} onClick={onArrowClick} />
                </div>
                <SellerProcessExplanation
                    title1={sellerProcessExplanationTitle1}
                    description1={sellerProcessExplanationDescription1}
                    title2={sellerProcessExplanationTitle2}
                    description2={sellerProcessExplanationDescription2}
                    title3={sellerProcessExplanationTitle3}
                    description3={sellerProcessExplanationDescription3}
                />
            </div>
        </section>
    );
};

export default AboveTheFold;
