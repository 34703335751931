import React from 'react';

import MainCtas from '../MainCTAs/MainCTAs';
import styles from './HowIwocaPayFits.module.scss';
import HowIwocaPayFitsDesktopImage from './HowIwocaPayFitsDesktopImage';
import classnames from 'classnames';

const HowIwocaPayFits = ({
    heading,
    title1,
    description1,
    title2,
    description2,
    title3,
    description3,
    buttonHref,
    button
}) => {
    return (
        <section id="howitworks" className={styles.Section}>
            <h2 className={styles.Heading}>{heading}</h2>
            <div className={styles.SectionInner}>
                <div className={styles.ImageContainer}>
                    <div className={styles.CTAArea}>
                        <HowIwocaPayFitsDesktopImage
                            className={styles.DesktopImage}
                        />
                        <MainCtas
                            className={styles.mainCtaDesktop}
                            primaryButton={button}
                            primaryButtonHref={buttonHref}
                            primaryButonGaId="steps__iwocapaycta"
                            secondaryButonGaId="steps__iwocapaybookingcta"
                        />
                    </div>
                </div>
                <div className={styles.TextContainer}>
                    <div className={styles.ExplanationGroup}>
                        <div className={styles.ExplanationGroupShapes}>
                            <div className={classnames(styles.Process)}>1</div>
                        </div>
                        <div className={styles.ExplanationGroupText}>
                            <h3 className={styles.ThinSubHeading}>{title1}</h3>
                            <p className={styles.Description}>{description1}</p>
                        </div>
                    </div>
                    <div className={styles.ExplanationGroup}>
                        <div className={styles.ExplanationGroupShapes}>
                            <div className={classnames(styles.Process)}>2</div>
                        </div>
                        <div className={styles.ExplanationGroupText}>
                            <h3 className={styles.SubHeading}>{title2}</h3>
                            <p className={styles.Description}>{description2}</p>
                        </div>
                    </div>
                    <div className={styles.ExplanationGroup}>
                        <div className={styles.ExplanationGroupShapes}>
                            <div className={classnames(styles.Process)}>3</div>
                        </div>
                        <div className={styles.ExplanationGroupText}>
                            <h3 className={styles.SubHeading}>{title3}</h3>
                            <p className={styles.Description}>{description3}</p>
                        </div>
                    </div>
                </div>
                <MainCtas
                    className={styles.mainCtaMobile}
                    primaryButton={button}
                    primaryButtonHref={buttonHref}
                />
            </div>
        </section>
    );
};

export default HowIwocaPayFits;
