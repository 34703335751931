import React from 'react';

const GetPaidUpFrontImage = ({ className, fill = 'none' }) => (
    <svg className={className} width={336} viewBox="0 0 336 154" fill="none">
        <path
            d="M179.129 46.528c-4.878 2.046-13.18 2.36-16.721 2.262l-8.851 13.377c2.229.197 8.065.315 13.573-.787 5.508-1.102 34.556-16 48.391-23.31-.032-.919-.845-2.892-3.835-3.443-3.738-.688-26.458 9.344-32.557 11.901z"
            fill="#EDCA6C"
        />
        <path
            d="M207.389 43.17l-4.851.21-5.164 3.492 10.139-.3a1.703 1.703 0 10-.124-3.403z"
            fill="#EDCA6C"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M219.032 112.439c-.479 5.705.755 9.574 3.827 11.799 1.021.74 2.029.92 3.602.427 1.847-.58 4.174-2.058 6.811-4.511 5.23-4.866 10.109-12.078 12.988-17.19l10.264 5.779c-3.169 5.63-8.746 14.005-15.228 20.036-3.221 2.996-7.064 5.794-11.308 7.126-4.518 1.417-9.522 1.145-14.038-2.126-8.003-5.796-9.277-14.937-8.657-22.325.625-7.457 3.255-14.717 4.972-19.008l10.937 4.375c-1.625 4.064-3.698 9.981-4.17 15.618z"
            fill="#FFA0A0"
        />
        <path
            d="M225.078 85.932l-1.855 11.052c-5.126-.106-17.085-.602-23.913-1.736-6.828-1.133-7.857-8.022-7.519-11.325l33.287 2.009z"
            fill="#FB534A"
        />
        <path
            d="M182.866 2.556c-1.809-2.204-14.393 4.13-20.459 7.574l3.64 3.835c6.36-2.885 18.629-9.206 16.819-11.41z"
            fill="#699FE3"
        />
        <path
            d="M63.853 105.542c7.082 2.832 12.984-3.476 15.05-6.984L68.573 86.263 40.248 66.789l-11.213 10.13c8.656 8.36 27.737 25.79 34.818 28.623zM126.509 145.081l-8.066-37.769c1.476-4.557 5.567-13.75 10.131-14.065 4.564-.314 5.967 4.984 6.098 7.672l4.623 39.539-12.786 4.623z"
            fill="#EDCA6C"
        />
        <path
            d="M58.74 77.903l14.95-12 34.818 8.46 30.097 15.54-23.015 20.064-23.016-15.343-11.802 10.426-22.033-27.147z"
            fill="#699FE3"
        />
        <path
            d="M165.161 46.529c-6.137.314-21.441-3.737-28.326-5.803l-22.23 29.802c1.902-.164 7.142-.393 12.886 0 5.744.393 18.523 2.459 24.195 3.443l13.475-27.442zM147.655 3.153c-11.803 1.888-12.787 16.917-11.803 24.195l30.687-13.573c-1.377-4.328-7.082-12.51-18.884-10.622z"
            fill="#FB534A"
        />
        <path
            d="M147.655 3.152c-11.803 1.888-12.787 16.917-11.803 24.195l11.803-5.22c-1.968-8.243-.82-16.085 0-18.975z"
            fill="#699FE3"
        />
        <path
            d="M140.18 33.838l-6.984 2.656-5.016-6.59 38.325-16.234.526 6.694c1.508-.427 4.622-.826 5.016.983.492 2.262-1.18 4.328-3.639 6.295-2.459 1.967-11.311 6.196-19.377 8.852-6.452 2.125-8.589-.885-8.851-2.656z"
            fill="#EDCA6C"
        />
        <path
            d="M151.103 28.532a2.487 2.487 0 11-4.973 0 2.487 2.487 0 014.973 0z"
            fill="#1B2D37"
        />
        <path
            d="M40.248 66.789l-11.213 10.13a443.427 443.427 0 0014.073 12.975c.902.794 1.81 1.587 2.718 2.37l12.45-13.08-2.9-1.995-1.328-.913-13.8-9.487z"
            fill="#EEF3F7"
        />
        <path
            d="M43.081 89.964L55.35 77.259l-1.327-.913L41.98 88.99c.365.325.732.65 1.1.975z"
            fill="#5F8A53"
        />
        <path
            d="M139.294 140.458l-12.787 4.623-3.312-15.512-.426-1.996-.637-2.983 14.692-5.255.366 3.128.248 2.127 1.856 15.868z"
            fill="#EEF3F7"
        />
        <path
            d="M122.799 127.658l14.421-5.111.249 2.128-14.244 4.978-.426-1.995z"
            fill="#5F8A53"
        />
        <path
            d="M53.006 43.447l2.35-4.248 5.428-3.067-4.787 8.943a1.703 1.703 0 11-2.991-1.628z"
            fill="#EDCA6C"
        />
        <path
            d="M80.72 25.875l4.892 4.333 11.019-9.36c-4.233-3.374-12.883-7.991-15.05-8.484C78.875 11.75 59.129 23.445 49.266 32c-7.89 6.843-3.461 11.208-.26 12.535 7.1-4.997 22.184-15.65 25.712-18.277 1.641-1.223 3.914-1.815 6.003-.383z"
            fill="#EDCA6C"
        />
        <path
            d="M128.967 153.834l-2.951-10.918c5.573-1.869 18.688-6 26.556-7.573 7.869-1.574 12.065 4.393 13.18 7.573l-36.785 10.918z"
            fill="#5F8A53"
        />
        <path
            d="M165.751 142.917l-36.785 10.918-.504-1.866 36.629-10.542c.283.533.503 1.039.66 1.49z"
            fill="#1B2D37"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M142.45 143.74l-1.672-5.41.866-.268 1.672 5.411-.866.267zM144.762 143.052l-1.672-5.41.866-.268 1.672 5.41-.866.268z"
            fill="#1B2D37"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M143.966 136.757l2.984-.82a3.095 3.095 0 10-3.805-2.164l.821 2.984zm1.103-7.663a4 4 0 00-2.797 4.919l1.06 3.858 3.858-1.06a4.002 4.002 0 00-2.121-7.717z"
            fill="#1B2D37"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M142.408 137.154l-2.983.825a3.094 3.094 0 112.158-3.808l.825 2.983zm-4.876-6.014a4.002 4.002 0 014.924 2.789l1.067 3.857-3.856 1.067a4.002 4.002 0 01-2.135-7.713z"
            fill="#1B2D37"
        />
        <path
            d="M31.857 58.602l8.015 7.98c-3.893 4.405-13.185 14.54-19.212 19.837-6.028 5.297-12.646 2.228-15.201.031l26.398-27.848z"
            fill="#5F8A53"
        />
        <path
            d="M5.46 86.451l26.397-27.848 1.37 1.364L6.777 87.41c-.512-.32-.956-.649-1.318-.96z"
            fill="#1B2D37"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.226 74.088l4.153 3.85-.616.664-4.153-3.85.616-.664zM23.568 75.84l4.154 3.85-.616.665-4.154-3.85.616-.664z"
            fill="#1B2D37"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.407 80.893l-2.175 2.203a3.095 3.095 0 104.378-.028l-2.203-2.175zm2.876 7.189a4.001 4.001 0 00-.037-5.66l-2.848-2.81-2.81 2.848a4.001 4.001 0 005.695 5.622z"
            fill="#1B2D37"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.557 79.769l2.17-2.207a3.095 3.095 0 11.037 4.377l-2.207-2.17zm7.23 2.77a4.001 4.001 0 01-5.659.046l-2.852-2.806 2.806-2.852a4.001 4.001 0 015.705 5.612z"
            fill="#1B2D37"
        />
        <path
            d="M142.334 1.82a3.64 3.64 0 00-1.333 4.974l6.306-3.641a3.64 3.64 0 00-4.973-1.333z"
            fill="#246AC1"
        />
        <path
            d="M106.441 12.005l-22.523 17.9L93.95 39.84 65.918 64.724l38.753 18.786c12.261-10.524 36.037-33.677 33.047-42.097-2.99-8.419-22.097-23.113-31.277-29.408z"
            fill="#FB534A"
        />
        <path
            d="M228.476 108.001l37.476-61.348-3.819-28.882c17.187 1.193 54.426 4.391 65.884 7.638 11.458 3.246 6.843 18.221 3.103 25.303l-32.942 59.677-69.702-2.388z"
            fill="#ACC8D7"
        />
        <path
            d="M240.887 42.596l12.777-19.429a10.842 10.842 0 0112.635-4.278c6.237 2.18 9.092 9.384 6.04 15.244l-6.149 11.805-25.303-3.342z"
            fill="#DEE7EF"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M320.856 46.373l-38.193-6.207.727-4.472 38.193 6.207-.727 4.472zM316.319 59.594l-38.193-6.207.727-4.472 38.193 6.207-.727 4.472zM310.198 74.075l-38.193-6.207.727-4.472 38.193 6.207-.727 4.472zM303.633 88.494l-38.194-6.207.727-4.472 38.194 6.207-.727 4.472zM295.18 102.913l-38.194-6.207.727-4.472 38.193 6.207-.726 4.472z"
            fill="#3C647C"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M305.495 103.187c-6.544 1.618-15.324 6.03-21.51 9.304l-5.511-10.411c5.988-3.17 16.019-8.307 24.192-10.329 2.097-.519 4.291-.89 6.405-.913 2.065-.02 4.517.287 6.755 1.56 2.453 1.397 4.123 3.66 4.811 6.45.632 2.56.379 5.286-.266 7.927-1.188 4.86-4.753 11.94-7.857 17.911-.718 1.382-1.417 2.713-2.082 3.981-2.48 4.729-4.49 8.561-5.298 10.742l-11.047-4.091c1.1-2.969 3.641-7.807 6.176-12.63.606-1.154 1.213-2.308 1.799-3.435 3.327-6.401 6.088-12.092 6.866-15.276.135-.553.213-1.002.254-1.359-.885.01-2.111.179-3.687.569z"
            fill="#FFA0A0"
        />
        <path
            d="M293.179 145.523l.043-11.258c5.488-.526 18.333-1.506 25.817-1.218 7.483.289 9.788 7.037 10.006 10.375l-35.866 2.101z"
            fill="#FB534A"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M140.686 31.784a8.701 8.701 0 00-.074 1.76l-.904.06a9.517 9.517 0 01.083-1.96c.056-.36.142-.725.272-1.048.129-.318.316-.634.601-.846l.543.725c-.098.073-.206.219-.304.46a3.985 3.985 0 00-.217.85zM57.302 38.237l-2.21 2.388-.664-.615 2.209-2.388.665.615zM97.524 89.797l-4.058 4.418-.667-.613 4.058-4.418.667.613zM136.328 50.635l-16.829 20.05-.694-.582 16.829-20.051.694.583zM105.185 48.938c-4.797-2.403-9.262-6.36-11.912-9.27l.67-.61c2.603 2.859 6.981 6.732 11.648 9.07 4.685 2.346 9.494 3.072 13.146-.04l.587.69c-4.083 3.479-9.359 2.553-14.139.16z"
            fill="#1B2D37"
        />
        <path
            d="M286.723 107.515a5.848 5.848 0 11-11.696-.002 5.848 5.848 0 0111.696.002z"
            fill="#FFA0A0"
        />
    </svg>
);

export default GetPaidUpFrontImage;
