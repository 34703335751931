import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';

import classnames from 'classnames';

import MetaData from '../../../../components/Layout/MetaData.jsx';
import commonStyles from '../../iwocaPage.module.scss';
import styles from './IwocaPaySellersPageV2.module.scss';
import AboveTheFold from './AboveTheFold/AboveTheFold';
import HowIwocaPayFits from './HowIwocaPayFits/HowIwocaPayFits';
import NavBar from './NavBar/NavBar';
import GiveCustomersMore from './GiveCustomersMore/GiveCustomersMore';
import BuiltForSmallBusiness from './BuiltForSmallBusiness/BuiltForSmallBusiness';
import BottomCTA from './BottomCTA/BottomCTA';
import MakeGettingPaidEasier from './MakeGettingPaidEasier/MakeGettingPaidEasier';
import FooterContact from './FooterContact/FooterContact';
import FooterLinks from './FooterLinks/FooterLinks';
import Disclaimers from './Disclaimers/Disclaimers';
import HeaderCircleSVG from './shapes/HeaderCircleSVG.jsx';
import HeaderMobileHalfDoughnutSVG from './shapes/HeaderMobileHalfDoughnutSVG.jsx';
import BasicCircle from 'aphrodite-shared/SVGComponents/shapes/Circles/BasicCircle';
import SmallBusinessTeamSVG from 'aphrodite-shared/SVGComponents/SmallBusinessTeamSVG';

export const getCtaUrl = (location) => {
    let ctaHref = '/pay/onboarding/eligibility/';
    if (location.pathname === '/iwocapay-sellers/') {
        ctaHref = ctaHref.concat(location.search);
    }
    return ctaHref;
};

const IwocaPaySellersV2 = ({ data, location }) => {
    const { contentfulPageIwocaPaySellersPageV2: pageData } = data;
    const {
        title,
        metaDescription,
        allowSearchEngineScraping = true,
        url,
        headlineLong,
        sellerProcessExplanationTitle1,
        sellerProcessExplanationDescription1,
        sellerProcessExplanationTitle2,
        sellerProcessExplanationDescription2,
        sellerProcessExplanationTitle3,
        sellerProcessExplanationDescription3,
        sellerSalesProcessTitle,
        sellerSalesProcessStep1Title,
        sellerSalesProcessStep1Description,
        sellerSalesProcessStep2Title,
        sellerSalesProcessStep2Description,
        sellerSalesProcessStep3Title,
        sellerSalesProcessStep3Description,
        advantagesForBuyersTitle,
        advantagesForBuyersDescription,
        iwocaPayMissionTitle,
        iwocaPayMissionDescription,
        iwocaPaySummaryTitle,
        iwocaPaySummaryDescription,
        iwocaPayEmail,
        iwocaPayPhoneNumber
    } = pageData;
    const [isMounted, setIsMounted] = useState(false);
    const ctaText = 'Check eligibility';
    const ctaHref = getCtaUrl(location);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    if (!isMounted) {
        return null;
    }

    const pageClasses = classnames(
        commonStyles.Page,
        styles.IwocaPaySellersPageV2
    );

    return (
        <div className={pageClasses}>
            <MetaData
                metaData={{
                    titleTag: title,
                    metaDescription,
                    allowSearchEngineScraping,
                    url
                }}
            />
            <NavBar phoneNumber={iwocaPayPhoneNumber} />
            <HeaderCircleSVG className={styles.HeaderCircleShape} />
            <HeaderMobileHalfDoughnutSVG
                className={styles.HeaderHalfDoughnutShape}
            />
            <div className={styles.PageContent}>
                <AboveTheFold
                    headlineContent={headlineLong.headlineLong}
                    button={ctaText}
                    buttonHref={ctaHref}
                    sellerProcessExplanationTitle1={
                        sellerProcessExplanationTitle1
                    }
                    sellerProcessExplanationDescription1={
                        sellerProcessExplanationDescription1.sellerProcessExplanationDescription1
                    }
                    sellerProcessExplanationTitle2={
                        sellerProcessExplanationTitle2
                    }
                    sellerProcessExplanationDescription2={
                        sellerProcessExplanationDescription2.sellerProcessExplanationDescription2
                    }
                    sellerProcessExplanationTitle3={
                        sellerProcessExplanationTitle3
                    }
                    sellerProcessExplanationDescription3={
                        sellerProcessExplanationDescription3.sellerProcessExplanationDescription3
                    }
                />
                <HowIwocaPayFits
                    heading={sellerSalesProcessTitle}
                    button={ctaText}
                    buttonHref={ctaHref}
                    title1={sellerSalesProcessStep1Title}
                    description1={
                        sellerSalesProcessStep1Description.sellerSalesProcessStep1Description
                    }
                    title2={sellerSalesProcessStep2Title}
                    description2={
                        sellerSalesProcessStep2Description.sellerSalesProcessStep2Description
                    }
                    title3={sellerSalesProcessStep3Title}
                    description3={
                        sellerSalesProcessStep3Description.sellerSalesProcessStep3Description
                    }
                />
                <GiveCustomersMore
                    title={advantagesForBuyersTitle}
                    description={
                        advantagesForBuyersDescription.advantagesForBuyersDescription
                    }
                    button={ctaText}
                    buttonHref={ctaHref}
                />

                <BuiltForSmallBusiness
                    title={iwocaPayMissionTitle}
                    description={
                        iwocaPayMissionDescription.iwocaPayMissionDescription
                    }
                />
                <SmallBusinessTeamSVG className={styles.WorkingTogetherSVG} />
                <div className={styles.BottomArea}>
                    <BasicCircle
                        className={styles.BasicCircleBottomPage}
                        size="lg"
                    />
                    <div className={styles.BottomAreaContent}>
                        <BottomCTA button={ctaText} buttonHref={ctaHref} />
                        <MakeGettingPaidEasier
                            title={iwocaPaySummaryTitle}
                            description={
                                iwocaPaySummaryDescription.iwocaPaySummaryDescription
                            }
                        />
                        <FooterContact
                            iwocaPayEmail={iwocaPayEmail}
                            iwocaPayPhoneNumber={iwocaPayPhoneNumber}
                        />
                        <FooterLinks />
                        <Disclaimers />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IwocaPaySellersV2;

export const query = graphql`
    query IwocaPaySellersPageV2Query($id: String!) {
        contentfulPageIwocaPaySellersPageV2(id: { eq: $id }) {
            title
            url
            metaDescription
            allowSearchEngineScraping
            headlineLong {
                headlineLong
            }
            sellerProcessExplanationTitle1
            sellerProcessExplanationDescription1 {
                sellerProcessExplanationDescription1
            }
            sellerProcessExplanationTitle2
            sellerProcessExplanationDescription2 {
                sellerProcessExplanationDescription2
            }
            sellerProcessExplanationTitle3
            sellerProcessExplanationDescription3 {
                sellerProcessExplanationDescription3
            }
            sellerSalesProcessTitle
            sellerSalesProcessStep1Title
            sellerSalesProcessStep1Description {
                sellerSalesProcessStep1Description
            }
            sellerSalesProcessStep2Title
            sellerSalesProcessStep2Description {
                sellerSalesProcessStep2Description
            }
            sellerSalesProcessStep3Title
            sellerSalesProcessStep3Description {
                sellerSalesProcessStep3Description
            }
            advantagesForBuyersTitle
            advantagesForBuyersDescription {
                advantagesForBuyersDescription
            }
            iwocaPayMissionTitle
            iwocaPayMissionDescription {
                iwocaPayMissionDescription
            }
            iwocaPaySummaryTitle
            iwocaPaySummaryDescription {
                iwocaPaySummaryDescription
            }
            iwocaPayEmail
            iwocaPayPhoneNumber
        }
    }
`;
