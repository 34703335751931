import React from 'react';

const HeaderMobileHalfDoughnutSVG = (props) => {
    const { className } = props;
    return (
        <svg
            className={className}
            width="2150"
            height="1123"
            viewBox="0 0 375 953"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M688.712 953C707.52 749.477 601.32 546.447 406.65 452.519C155.897 330.615 -145.007 435.563 -266.833 686.474C-308.255 772.43 -323.285 864.383 -315.063 953H-886.927C-887.639 937.077 -887.998 921.117 -888 905.132V904.911C-887.984 747.579 -853.352 587.8 -780.247 436.708C-594.805 53.2642 -211.437 -169.97 187.668 -170C187.721 -170 187.774 -170 187.827 -170C345.094 -169.988 504.801 -135.318 655.8 -62.1348C1031.8 119.857 1253.56 492.247 1262 883.28V929.622C1261.83 937.411 1261.58 945.204 1261.24 953H688.712Z"
                fill="#184681"
            />
        </svg>
    );
};

export default HeaderMobileHalfDoughnutSVG;
