import React from 'react';
import Button from 'aphrodite-shared/ui/Button/Button';

import classnames from 'classnames';

import styles from './MainCTAs.module.scss';

const MainCTAs = ({
    className,
    primaryButton,
    primaryButtonHref,
    primaryButonGaId,
    secondaryButonGaId
}) => {
    return (
        <div className={classnames(styles.buttonContainer, className)}>
            <Button
                href={primaryButtonHref}
                colored
                colour="tertiary"
                dataGaId={primaryButonGaId}
                className={styles.eligibilityButton}
                title={primaryButton}>
                {primaryButton}
            </Button>
            <Button
                href="https://meetings.hubspot.com/n-stokes/iwocapay-demo"
                newTab={true}
                colored
                colour="primary"
                dataGaId={secondaryButonGaId}
                className={styles.Button}>
                Book a demo
            </Button>
        </div>
    );
};

export default MainCTAs;
