export function buildI12NPhoneLinkfromUKPhoneNumber(ukPhoneNumber) {
    if (!ukPhoneNumber) {
        return;
    }
    if (ukPhoneNumber.startsWith('00')) {
        ukPhoneNumber = ukPhoneNumber.replace('00', '+');
    } else if (ukPhoneNumber.startsWith('0')) {
        ukPhoneNumber = ukPhoneNumber.replace('0', '+44');
    }
    const phoneLink = `tel:${ukPhoneNumber.replace(/ /g, '')}`;
    return phoneLink;
}
