import React from 'react';

const GetMoreBusinessImage = ({ className }) => (
    <svg width={179} viewBox="0 0 179 264" fill="none" className={className}>
        <path
            d="M112.013 251.049c-.396 4.997-4.775 8.729-9.782 8.334-5.006-.394-8.745-4.766-8.35-9.764.396-4.998 4.775-8.729 9.782-8.334 5.007.394 8.745 4.766 8.35 9.764zM155.438 248.894c-.395 4.998-4.774 8.73-9.781 8.335-5.007-.395-8.745-4.766-8.35-9.764.396-4.998 4.775-8.729 9.782-8.335 5.007.395 8.745 4.766 8.349 9.764z"
            fill="#111C22"
        />
        <path
            d="M131 230.511l-7.002-52.837 24.102 7.775 7.499 40.98c5.726 1.382 17.396 7.364 18.261 20.239l-25.28 7.113c-1.271-16.667-12.25-22.458-17.58-23.27z"
            fill="#699FE3"
        />
        <path
            d="M144.883 190.497l-15.146-3.554 5.276 38.308 15.993-2.148-6.123-32.606z"
            fill="#fff"
        />
        <path
            d="M82.776 99.454c0 12.885-10.464 23.329-23.37 23.329-12.908 0-23.37-10.444-23.37-23.329 0-12.884 10.462-23.329 23.37-23.329 12.906 0 23.37 10.445 23.37 23.33z"
            fill="#699FE3"
        />
        <path
            d="M82.023 105.249c-3.396 12.57-16.357 20.011-28.948 16.621C74.1 111.594 76.354 90.63 74.853 81.433c6.51 5.745 9.587 14.874 7.17 23.816z"
            fill="#699FE3"
            style={{
                mixBlendMode: 'multiply'
            }}
        />
        <path
            d="M24.556 15.145c0 3.843-3.304 6.958-7.38 6.958-4.076 0-7.38-3.115-7.38-6.958 0-3.842 3.304-6.957 7.38-6.957 4.076 0 7.38 3.115 7.38 6.957z"
            fill="#699FE3"
        />
        <path
            d="M21.827 20.477a7.02 7.02 0 001.251-9.86c-.043 2.992-2.171 9.075-10.342 9.47a7.051 7.051 0 009.09.39z"
            fill="#699FE3"
            style={{
                mixBlendMode: 'multiply'
            }}
        />
        <path
            d="M49.996 37.566L24.947 53.54 23.614 23.9l26.382 13.667z"
            fill="#2B4959"
        />
        <path d="M.774 0h6.56v8.186H.774V0z" fill="#FB534A" />
        <path d="M7.334.33l.82-.33v7.856l-.82.33V.329z" fill="#DC1005" />
        <path
            d="M155.521 244.176c.35 1.919-.743 3.724-2.439 4.032-1.697.308-3.356-.998-3.705-2.917-.35-1.919.743-3.724 2.439-4.032 1.697-.308 3.356.998 3.705 2.917zM170.149 240.397c.293 1.612-.625 3.128-2.05 3.387-1.426.259-2.819-.838-3.113-2.45-.293-1.612.625-3.128 2.05-3.387 1.426-.258 2.819.839 3.113 2.45z"
            fill="#fff"
        />
        <path
            d="M133.615 216.117l12.217-20.479 3.363 17.746-6.667 11.504-7.482 1.037-1.431-9.808z"
            fill="#F2F8FF"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M138.554 219.215a.295.295 0 01.368.192l1.75 5.567a.292.292 0 01-.192.367.295.295 0 01-.368-.192l-1.75-5.566a.293.293 0 01.192-.368zM147.383 217.905a.293.293 0 00-.231.344l1.131 5.724a.294.294 0 00.576-.114l-1.131-5.724a.294.294 0 00-.345-.23z"
            fill="#000"
        />
        <path
            d="M77.807 179.172l-15.532.908 52.413-9.004h9.908l-46.79 8.096z"
            fill="#D6AF4B"
        />
        <path
            d="M18.083 57.114l32.708-15.197 14.032 30.092-32.708 15.197-14.032-30.092z"
            fill="#6A9A5C"
        />
        <path
            d="M50.766 41.97l6.584.85 12.664 27.919-5.217 1.323L50.766 41.97z"
            fill="#4A6C41"
        />
        <path
            d="M41.826 124.902l62.549-11.795 10.891 57.547-62.55 11.796-10.89-57.548z"
            fill="#ECC357"
        />
        <path
            d="M104.309 113.194l10.89 4.31 9.497 53.237h-9.497l-10.89-57.547z"
            fill="#EB7F14"
        />
        <path
            d="M123.996 177.683c-6.415-.741-55.857 2.45-79.776 4.138 0 0 7.84 55.253 12.034 72.915l92.324-.947c-1.271-16.667-12.25-22.457-17.58-23.269l-7.002-52.837z"
            fill="#246AC1"
        />
        <path
            d="M84.378 254.449c-.396 4.998-4.775 8.73-9.782 8.335-5.006-.395-8.745-4.766-8.35-9.764.396-4.998 4.776-8.73 9.782-8.335 5.007.395 8.745 4.766 8.35 9.764zM127.805 252.295c-.396 4.998-4.775 8.73-9.782 8.335-5.007-.395-8.745-4.766-8.349-9.764.395-4.998 4.774-8.73 9.781-8.335 5.007.395 8.745 4.766 8.35 9.764z"
            fill="#1B2D37"
        />
        <path
            d="M104.109 187.133l4.666 40.982 16.585-1.762-4.529-29.576c-.919-5.997-6.272-10.301-12.337-9.919l-4.385.275z"
            fill="#fff"
        />
        <path
            d="M107.375 214.765l13.354-18.311 2.226 14.877-14.003 17.047-1.577-13.613z"
            fill="#F2F8FF"
        />
        <path
            d="M53.093 245.026a2.382 2.382 0 014.681-.876l1.057 5.625a2.376 2.376 0 01-1.902 2.774 2.38 2.38 0 01-2.78-1.898l-1.056-5.625z"
            fill="#fff"
        />
        <path
            d="M42.012 42.568L23.734 23.741l26.24 13.754-7.962 5.073z"
            fill="#2B4959"
            style={{
                mixBlendMode: 'multiply'
            }}
        />
    </svg>
);

export default GetMoreBusinessImage;
