import React from 'react';
import { useIntl } from 'react-intl';

import styles from './Disclaimers.module.scss';
import messages from '../../../../../components/Footer/Footer.messages';

const Disclaimers = () => {
    const { formatMessage } = useIntl();

    return (
        <section className={styles.DisclaimersBackground}>
            <div className={styles.Disclaimers}>
                <p>
                    {formatMessage({
                        ...messages.disclaimerIwocaAddress
                    })}
                </p>
                <p>
                    {formatMessage({
                        ...messages.disclaimer
                    })}
                </p>
            </div>
        </section>
    );
};

export default Disclaimers;
