import React, { useState } from 'react';
import classnames from 'classnames';

import IwocaPayLogoSVG from 'aphrodite-shared/SVGComponents/IwocaPayLogoSVG';
import styles from './NavBar.module.scss';
import { buildI12NPhoneLinkfromUKPhoneNumber } from '../FooterContact/helpers';
import NavBar from '../../../../../components/NavBar/NavBar';

const IwocaPayNavBar = ({ phoneNumber }) => {
    const [hasScrolled, setHasScrolled] = useState(false);
    const phoneLink = buildI12NPhoneLinkfromUKPhoneNumber(phoneNumber);

    window.addEventListener('scroll', () => {
        window.scrollY > 0 ? setHasScrolled(true) : setHasScrolled(false);
    });

    return (
        <>
            <NavBar
                className={classnames(styles.Section, {
                    [styles.navBarStyle]: hasScrolled
                })}
                HomeLogo={() => (
                    <IwocaPayLogoSVG className={styles.IwocaPayLogo} />
                )}
                phoneNumber={phoneNumber}
                phoneNumberLink={phoneLink}
                isIwocaPay={true}>
                {/* <PromotionBanner hasScrolled={hasScrolled} /> */}
            </NavBar>
        </>
    );
};

export default IwocaPayNavBar;
