import React from 'react';

const HeaderCircleSVG = (props) => {
    const { className } = props;
    return (
        <svg
            className={className}
            width="3513"
            height="2175"
            viewBox="0 0 2048 2175"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2780 -367V732.13C2466.3 771.727 2168.74 904.305 2068.9 1241.17C2051.2 1312.65 2025.88 1383.39 1992.56 1452.32C1842.58 1762.56 1563.15 1967.94 1250.62 2035.17L1250.99 2035.31L1238.03 2037.8C1234.52 2038.51 1231.01 2039.21 1227.49 2039.88V2039.83L1210.68 2043.06C1203.43 2044.44 1188.99 2043.14 1188.99 2031V1627.59C1188.87 1621.84 1191.83 1612.64 1203.36 1610.35C1215.12 1608.01 1229.5 1605.08 1234.53 1603.64C1465.03 1516.86 1628.99 1294.33 1628.99 1033.5C1628.99 697.158 1356.34 424.5 1019.99 424.5C683.655 424.5 410.996 697.158 410.996 1033.5V1034.1L410.997 1034.5L410.996 1136.38C410.996 1221.58 328.167 1241.19 269.996 1240.48V1240.5H-733.004L-733.004 982.52L-733.004 332.5L-733.004 301.5L-733.004 -366.5L2068.6 -366.5V-367H2780Z"
                fill="#184681"
            />
        </svg>
    );
};

export default HeaderCircleSVG;
