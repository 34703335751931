import React from 'react';

const GiveBetterTermsImage = ({ className, fill = 'none' }) => (
    <svg className={className} width={238} viewBox="0 0 238 281" fill="none">
        <path
            d="M171.712 58.259c-1.85 2.396-2.325 7.957-3.726 10.475-1.018 1.831-2.814 1.317-2.761-5.728.051-6.877 1.558-10.448 5.535-13.22 2.338-1.63 6.647-2.797 9.08-2.458-1.662-2.114-4.29-6.045-6.843-10.17-3.498-5.653-5.905-8.223-7.808-13.867-1.425-4.23 1.48-3.048 3.457-1.165 1.436 1.367 12.11 12.348 17.605 18.132-9.699-22.095-9.819-25.767-8.595-26.384 1.224-.617 5.477 5.865 6.626 7.658.69 1.076 6.842 9.72 9.921 13.795-4.711-15.185-7.422-25.895-5.182-27.502 1.2-.86 3.126 4.4 4.424 7.644 1.298 3.244 8.299 20.385 13.471 27.232 4.138 5.478 15.13 3.217 20.109 1.402l9.359 30.186c-23.16 10.133-43.73 15.867-52.689 12.956-8.959-2.912-16.242-10.023-14.345-11.072 1.896-1.048 10.184 4.566 14.046 2.833 8.106-3.638 8.833-15.277 3.567-19.153-5.139-3.782-13.012-4.493-15.251-1.594z"
            fill="#699FE3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M183.193 48.785a23 23 0 00-3.077-1.172l.106-.369c.71.205 1.891.617 3.132 1.193 1.24.574 2.555 1.316 3.525 2.184l-.257.286c-.928-.83-2.203-1.554-3.429-2.122zM190.085 45.035l-3.547-4.164.292-.249 3.547 4.164-.292.25zM196.456 39.66l-2.79-4.704.33-.196 2.791 4.704-.331.196zM204.432 83.284c-1.376 1.362-2.838 2.382-3.771 2.906l-.188-.335c.904-.508 2.339-1.507 3.688-2.844 1.351-1.338 2.603-3.003 3.163-4.84l.368.111c-.586 1.923-1.887 3.64-3.26 5.002z"
            fill="#4D809E"
            style={{
                mixBlendMode: 'multiply'
            }}
        />
        <path
            d="M61.132 104.26l111.71-27.796-2.318-9.315-111.71 27.796 2.318 9.315z"
            fill="#213845"
        />
        <path
            d="M61.132 104.26l26.788-6.666-2.318-9.314-26.788 6.665 2.318 9.315z"
            fill="#FB534A"
        />
        <path
            d="M35.054 185.511l8.83 2.366-1.047 3.907-8.83-2.366 1.047-3.907zM52.174 120.115l8.83 2.365-1.047 3.907-8.83-2.366 1.047-3.906z"
            fill="#DCA02E"
        />
        <path
            d="M86.334 166.353c-5.12 19.109-24.763 30.45-43.872 25.329-19.11-5.12-30.45-24.762-25.33-43.872 5.12-19.109 24.763-30.45 43.872-25.329 19.11 5.12 30.45 24.762 25.33 43.872z"
            fill="#EDCA6C"
        />
        <path
            d="M86.334 166.353c-5.12 19.109-24.763 30.45-43.872 25.329-19.11-5.12-30.45-24.762-25.33-43.872 5.12-19.109 24.763-30.45 43.872-25.329 19.11 5.12 30.45 24.762 25.33 43.872z"
            fill="#EDCA6C"
            style={{
                mixBlendMode: 'multiply'
            }}
        />
        <path
            d="M78.473 164.245c-5.12 19.109-24.762 30.45-43.872 25.33-19.11-5.121-30.45-24.763-25.33-43.873 5.121-19.109 24.763-30.45 43.873-25.329 19.11 5.12 30.45 24.762 25.33 43.872z"
            fill="#EDCA6C"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35.94 184.591c16.294 4.366 33.042-5.303 37.408-21.597s-5.303-33.042-21.597-37.408-33.043 5.303-37.409 21.597 5.304 33.042 21.598 37.408zm-.289 1.079c16.89 4.526 34.25-5.497 38.776-22.387 4.526-16.89-5.498-34.251-22.387-38.776-16.89-4.526-34.25 5.497-38.776 22.387-4.526 16.89 5.497 34.251 22.387 38.776z"
            fill="#DCA02E"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.61 148.247a.557.557 0 01.597.082l7.907 6.744 5.546-8.723a.558.558 0 01.858-.103l7.746 7.434 6.908-7.327a.56.56 0 01.826.015l6.102 6.965 6.76-5.928a.56.56 0 01.922.352l1.35 10.987a.558.558 0 01-.518.626l-44.682 2.839a.562.562 0 01-.594-.555l-.052-12.899a.56.56 0 01.324-.509zm.842 12.811l43.5-2.764-1.15-9.352-6.386 5.6a.56.56 0 01-.788-.052l-6.065-6.923-6.873 7.29a.56.56 0 01-.793.02l-7.66-7.352-5.525 8.69a.56.56 0 01-.834.125l-7.47-6.372.044 11.09z"
            fill="#DCA02E"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M81.694 140.085l-7.351-1.97.289-1.078 7.351 1.969-.289 1.079zM77.945 135.699l-7.351-1.97.289-1.078 7.351 1.969-.289 1.079zM73.398 130.809l-7.351-1.97.289-1.079 7.351 1.97-.289 1.079zM68.991 127.325l-7.351-1.97.289-1.079 7.351 1.97-.289 1.079zM84.59 146.229l-8.073-2.163.289-1.078 8.073 2.163-.29 1.078zM85.64 151.192l-6.848-1.835.29-1.079 6.847 1.835-.29 1.079zM86.178 156.702l-7.273-1.949.29-1.079 7.272 1.949-.289 1.079zM85.6 162.71l-7.273-1.949.29-1.079 7.272 1.949-.289 1.079zM84.563 166.578l-7.273-1.949.29-1.078 7.272 1.948-.289 1.079zM79.605 177.778l-7.273-1.949.29-1.078 7.272 1.949-.289 1.078zM76.369 180.911l-7.273-1.948.289-1.079 7.273 1.949-.29 1.078zM73.082 184.369l-7.272-1.949.289-1.079 7.272 1.949-.288 1.079zM68.876 187.124l-7.273-1.949.29-1.079 7.272 1.949-.289 1.079zM63.62 189.163l-7.273-1.949.289-1.078 7.273 1.949-.29 1.078zM58.041 192.249L50.77 190.3l.289-1.079 7.273 1.949-.29 1.079zM83.03 172.303l-7.272-1.949.289-1.078 7.273 1.948-.29 1.079z"
            fill="#EDCA6C"
        />
        <path
            d="M25.622 211.794h51.695l4.885 69.121H21.145l4.477-69.121z"
            fill="#3C647C"
        />
        <mask
            id="prefix__a"
            maskUnits="userSpaceOnUse"
            x={21}
            y={211}
            width={62}
            height={70}>
            <path
                d="M25.5 211.673h51.848l4.899 69.07H21.01l4.49-69.07z"
                fill="#3C647C"
            />
        </mask>
        <g mask="url(#prefix__a)">
            <path
                d="M72.46 286.796c-22.333-7.444-32.88-58.581-35.362-83.219l-13.293-2.394-11.433 85.613h60.089z"
                fill="#3C647C"
                style={{
                    mixBlendMode: 'multiply'
                }}
            />
        </g>
        <path d="M4.633 205.266h91.01v12.288H4.633v-12.288z" fill="#3C647C" />
        <mask
            id="prefix__b"
            maskUnits="userSpaceOnUse"
            x={4}
            y={205}
            width={92}
            height={13}>
            <path
                d="M4.633 205.266h91.143v12.105H4.633v-12.105z"
                fill="#3C647C"
            />
        </mask>
        <g mask="url(#prefix__b)">
            <path
                d="M63.65 221.125c-34.67-2.979-56.1-13.472-62.482-18.346l-2.393 20.473 64.874-2.127z"
                fill="#3C647C"
                style={{
                    mixBlendMode: 'multiply'
                }}
            />
        </g>
        <path
            d="M79.163 236.764l-55.427-.01-.672 10.369h56.833l-.734-10.359z"
            fill="#FB534A"
        />
        <path
            d="M52.408 85.811l-9.659-7.513 7.982-4.608 1.677 12.121zM58.41 85.811l1.678-12.12 7.981 4.607-9.658 7.513zM49.877 93.486l-11.232 4.656V88.83l11.232 4.656z"
            fill="#EDCA6C"
        />
    </svg>
);

export default GiveBetterTermsImage;
