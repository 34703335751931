import React from 'react';
import MainCtas from '../MainCTAs/MainCTAs';

import styles from './BottomCTA.module.scss';

const BottomCTA = (props) => {
    const { button, buttonHref } = props;
    return (
        <section className={styles.bottomCTA}>
            <MainCtas
                primaryButton={button}
                primaryButtonHref={buttonHref}
                primaryButonGaId="bottom__iwocapaycta"
                secondaryButonGaId="bottom__iwocapaybookingcta"
            />
        </section>
    );
};

export default BottomCTA;
