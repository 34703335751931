import React from 'react';
import ReactMarkdown from 'react-markdown';

import styles from './MakeGettingPaidEasier.module.scss';

const MakeGettingPaidEasier = (props) => {
    const { title, description } = props;
    return (
        <section className={styles.MakeGettingPaidEasierBackground}>
            <div className={styles.MakeGettingPaidEasier}>
                <h2>{title}</h2>
                <ReactMarkdown source={description} />
            </div>
        </section>
    );
};

export default MakeGettingPaidEasier;
