import React from 'react';

import styles from './FooterContact.module.scss';
import IwocaPayLogoSVG from 'aphrodite-shared/SVGComponents/IwocaPayLogoSVG';
import EmailIconSVG from 'aphrodite-shared/SVGComponents/EmailIconSVG';
import TelIconSVG from 'aphrodite-shared/SVGComponents/TelIconSVG';
import DynamicTrustpilot from 'aphrodite-shared/ui/DynamicTrustpilot/DynamicTrustpilot';

import { buildI12NPhoneLinkfromUKPhoneNumber } from './helpers';

const FooterContact = ({ iwocaPayEmail, iwocaPayPhoneNumber }) => {
    const emailLink = `mailto:${iwocaPayEmail}`;
    const phoneLink = buildI12NPhoneLinkfromUKPhoneNumber(iwocaPayPhoneNumber);

    return (
        <section className={styles.FooterContactBackground}>
            <div className={styles.FooterContact}>
                <div className={styles.IwocaPayLogo}>
                    <IwocaPayLogoSVG color="#fb534a" />
                </div>
                <div className={styles.Email}>
                    <EmailIconSVG />
                    <a href={emailLink} data-ga-id="footer__iwocapayemail">
                        {iwocaPayEmail}
                    </a>
                </div>
                <div className={styles.Tel}>
                    <TelIconSVG />
                    <a href={phoneLink} data-ga-id="footer__iwocapayphone">
                        {iwocaPayPhoneNumber}
                    </a>
                </div>
                <DynamicTrustpilot className={styles.DynamicTrustpilot} />
            </div>
        </section>
    );
};

export default FooterContact;
