import React from 'react';

const EmailIconSVG = ({ className }) => (
    <svg
        className={className}
        width="26"
        height="21"
        viewBox="0 0 26 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.9287 4.23853V17.5243C25.9287 18.9529 24.7858 20.0957 23.3573 20.0957H2.57144C1.14286 20.0957 0 18.9529 0 17.5243V4.23853L12.5715 12.6671C12.8572 12.81 13.1429 12.81 13.3572 12.6671L25.9287 4.23853Z"
            fill="#FF594B"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.57116 0.0957031H23.357C24.4998 0.0957031 25.4998 0.881421 25.857 1.88143L12.9998 10.4529L0.142578 1.88143C0.428294 0.881421 1.4283 0.0957031 2.57116 0.0957031Z"
            fill="#FF594B"
        />
    </svg>
);

export default EmailIconSVG;
