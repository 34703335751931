import React from 'react';

import styles from './GiveCustomersMore.module.scss';
import ReactMarkdown from 'react-markdown';
import GiveCustomersMoreSVG from 'aphrodite-shared/SVGComponents/GiveCustomersMoreSVG';
import BasicDoughnut from 'aphrodite-shared/SVGComponents/shapes/Doughnuts/BasicDoughnut';
import MainCtas from '../MainCTAs/MainCTAs';

const GiveCustomersMore = ({ title, description, button, buttonHref }) => {
    return (
        <section className={styles.Section}>
            <div className={styles.SectionInner}>
                <div className={styles.ImageContainer}>
                    <BasicDoughnut
                        size="xlg"
                        color="denim"
                        className={styles.BasicDoughnut}
                    />
                    <GiveCustomersMoreSVG
                        className={styles.giveCustomersMoreDesktop}
                    />
                </div>
                <div className={styles.TextContainer}>
                    <h2 className={styles.Title}>{title}</h2>
                    <GiveCustomersMoreSVG
                        className={styles.giveCustomersMoreMobile}
                    />
                    <ReactMarkdown
                        className={styles.MarkdownDescription}
                        source={description}
                    />
                </div>
            </div>
            <MainCtas
                primaryButton={button}
                primaryButtonHref={buttonHref}
                primaryButonGaId="benefits__iwocapaycta"
                secondaryButonGaId="benefits__iwocapaybookingcta"
            />
        </section>
    );
};

export default GiveCustomersMore;
